/* If you need to add @import statements, do so up here */

@import "jit-refresh.css";
/* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind typography;
