/*
  Syntax Highlighting for Code Snippets

  https://www.bridgetownrb.com/docs/liquid/tags#stylesheets-for-syntax-highlighting

  Other styles available eg. https://github.com/jwarby/jekyll-pygments-themes
  To use another style, delete all styles in this file and replace them with
  the new styles. Or create your own!

*/

pre.highlight {
  max-width: 93vw;
  background-color: #161616;
}

.highlight .hll {
  background-color: #44475a
}

.highlight {
  background: #282a36;
  color: #f8f8f2
}

.highlight .c {
  color: #6272a4
}

/* Comment */
.highlight .err {
  color: #f8f8f2
}

/* Error */
.highlight .g {
  color: #f8f8f2
}

/* Generic */
.highlight .k {
  color: #ff79c6
}

/* Keyword */
.highlight .l {
  color: #f8f8f2
}

/* Literal */
.highlight .n {
  color: #f8f8f2
}

/* Name */
.highlight .o {
  color: #ff79c6
}

/* Operator */
.highlight .x {
  color: #f8f8f2
}

/* Other */
.highlight .p {
  color: #f8f8f2
}

/* Punctuation */
.highlight .ch {
  color: #6272a4
}

/* Comment.Hashbang */
.highlight .cm {
  color: #6272a4
}

/* Comment.Multiline */
.highlight .cp {
  color: #ff79c6
}

/* Comment.Preproc */
.highlight .cpf {
  color: #6272a4
}

/* Comment.PreprocFile */
.highlight .c1 {
  color: #6272a4
}

/* Comment.Single */
.highlight .cs {
  color: #6272a4
}

/* Comment.Special */
.highlight .gd {
  color: #8b080b
}

/* Generic.Deleted */
.highlight .ge {
  color: #f8f8f2;
  text-decoration: underline
}

/* Generic.Emph */
.highlight .ges {
  color: #f8f8f2;
  text-decoration: underline
}

/* Generic.EmphStrong */
.highlight .gr {
  color: #f8f8f2
}

/* Generic.Error */
.highlight .gh {
  color: #f8f8f2;
  font-weight: bold
}

/* Generic.Heading */
.highlight .gi {
  color: #f8f8f2;
  font-weight: bold
}

/* Generic.Inserted */
.highlight .go {
  color: #44475a
}

/* Generic.Output */
.highlight .gp {
  color: #f8f8f2
}

/* Generic.Prompt */
.highlight .gs {
  color: #f8f8f2
}

/* Generic.Strong */
.highlight .gu {
  color: #f8f8f2;
  font-weight: bold
}

/* Generic.Subheading */
.highlight .gt {
  color: #f8f8f2
}

/* Generic.Traceback */
.highlight .kc {
  color: #ff79c6
}

/* Keyword.Constant */
.highlight .kd {
  color: #8be9fd;
  font-style: italic
}

/* Keyword.Declaration */
.highlight .kn {
  color: #ff79c6
}

/* Keyword.Namespace */
.highlight .kp {
  color: #ff79c6
}

/* Keyword.Pseudo */
.highlight .kr {
  color: #ff79c6
}

/* Keyword.Reserved */
.highlight .kt {
  color: #8be9fd
}

/* Keyword.Type */
.highlight .ld {
  color: #f8f8f2
}

/* Literal.Date */
.highlight .m {
  color: #ffb86c
}

/* Literal.Number */
.highlight .s {
  color: #bd93f9
}

/* Literal.String */
.highlight .na {
  color: #50fa7b
}

/* Name.Attribute */
.highlight .nb {
  color: #8be9fd;
  font-style: italic
}

/* Name.Builtin */
.highlight .nc {
  color: #50fa7b
}

/* Name.Class */
.highlight .no {
  color: #f8f8f2
}

/* Name.Constant */
.highlight .nd {
  color: #f8f8f2
}

/* Name.Decorator */
.highlight .ni {
  color: #f8f8f2
}

/* Name.Entity */
.highlight .ne {
  color: #f8f8f2
}

/* Name.Exception */
.highlight .nf {
  color: #50fa7b
}

/* Name.Function */
.highlight .nl {
  color: #8be9fd;
  font-style: italic
}

/* Name.Label */
.highlight .nn {
  color: #f8f8f2
}

/* Name.Namespace */
.highlight .nx {
  color: #f8f8f2
}

/* Name.Other */
.highlight .py {
  color: #f8f8f2
}

/* Name.Property */
.highlight .nt {
  color: #ff79c6
}

/* Name.Tag */
.highlight .nv {
  color: #8be9fd;
  font-style: italic
}

/* Name.Variable */
.highlight .ow {
  color: #ff79c6
}

/* Operator.Word */
.highlight .pm {
  color: #f8f8f2
}

/* Punctuation.Marker */
.highlight .w {
  color: #f8f8f2
}

/* Text.Whitespace */
.highlight .mb {
  color: #ffb86c
}

/* Literal.Number.Bin */
.highlight .mf {
  color: #ffb86c
}

/* Literal.Number.Float */
.highlight .mh {
  color: #ffb86c
}

/* Literal.Number.Hex */
.highlight .mi {
  color: #ffb86c
}

/* Literal.Number.Integer */
.highlight .mo {
  color: #ffb86c
}

/* Literal.Number.Oct */
.highlight .sa {
  color: #bd93f9
}

/* Literal.String.Affix */
.highlight .sb {
  color: #bd93f9
}

/* Literal.String.Backtick */
.highlight .sc {
  color: #bd93f9
}

/* Literal.String.Char */
.highlight .dl {
  color: #bd93f9
}

/* Literal.String.Delimiter */
.highlight .sd {
  color: #bd93f9
}

/* Literal.String.Doc */
.highlight .s2 {
  color: #bd93f9
}

/* Literal.String.Double */
.highlight .se {
  color: #bd93f9
}

/* Literal.String.Escape */
.highlight .sh {
  color: #bd93f9
}

/* Literal.String.Heredoc */
.highlight .si {
  color: #bd93f9
}

/* Literal.String.Interpol */
.highlight .sx {
  color: #bd93f9
}

/* Literal.String.Other */
.highlight .sr {
  color: #bd93f9
}

/* Literal.String.Regex */
.highlight .s1 {
  color: #bd93f9
}

/* Literal.String.Single */
.highlight .ss {
  color: #bd93f9
}

/* Literal.String.Symbol */
.highlight .bp {
  color: #f8f8f2;
  font-style: italic
}

/* Name.Builtin.Pseudo */
.highlight .fm {
  color: #50fa7b
}

/* Name.Function.Magic */
.highlight .vc {
  color: #8be9fd;
  font-style: italic
}

/* Name.Variable.Class */
.highlight .vg {
  color: #8be9fd;
  font-style: italic
}

/* Name.Variable.Global */
.highlight .vi {
  color: #8be9fd;
  font-style: italic
}

/* Name.Variable.Instance */
.highlight .vm {
  color: #8be9fd;
  font-style: italic
}

/* Name.Variable.Magic */
.highlight .il {
  color: #ffb86c
}

/* Literal.Number.Integer.Long */
